<template>
  <div class="how-get">
    <div class="how-get-content">
      <h2 class="how-get-content__title">
        {{ i18n.title }}
      </h2>
      <p
        class="how-get-content__text"
        v-html="i18n.caption"
      />
      <Stepper
        class="how-get-content__stepper"
        :steps="i18n.steps"
      />
      <button
        v-if="i18n.btn"
        class="__video-btn"
      >
        <Icon
          class="mr-4"
          style="width: 40px;
height: 40px;"
          type="play"
        />
        {{ i18n.btn.caption }}
      </button>
      <a
        v-if="i18n.links"
        class="how-get-content__text d-block mb-3"
        :href="i18n.links[0].link"
      >{{ i18n.links[0].title }}</a>
      <a
        v-if="i18n.links"
        class="how-get-content__text"
        :href="i18n.links[1].link"
      >{{ i18n.links[1].title }}</a>
    </div>
    <HowGetPhone class="how-get__img">
      <slot />
    </HowGetPhone>
  </div>
</template>

<style lang="css" src="./how-get.css"></style>

<script>
import Stepper from "../stepper/stepper.vue";
import HowGetPhone from "./phone/how-get-phone.vue";

export default {
  name: "HowGet",
  components: {
    Stepper,
    HowGetPhone,
  },
  props: {
    i18n: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
